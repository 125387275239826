export const reduxTypes = {
  GET_USER: 'getUser',
  SET_USER: 'setUser',
  SET_GENERAL: 'setGeneral',
  SET_ALERTDIALOG: 'setAlertDialog',
  RESET_ALERTDIALOG: 'resetAlertDialog',
  ADD_NOTIFICATION: 'addNotification',
  REMOVE_NOTIFICATION: 'removeNotification',
  ADD_NOTICE: 'addNotice',
  REMOVE_NOTICE: 'removeNotice',
  SET_BUG_ALERT: 'setBugAlert',
  RESET_BUG_ALERT: 'resetBugAlert',
  SET_DATA_LOADER: 'setDataLoader',
  RESET_DATA_LOADER: 'resetDataLoader',
  SET_PAGE: "setPage",
};
import types from "../../statics/types";
const initState = [];

export default function generalNoticeReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case types.reduxTypes.ADD_NOTICE:
      // console.log('add notification: ', data)
      return [ ...preState, data ];
    case types.reduxTypes.REMOVE_NOTICE:
      // console.log('remove notice: ', data)
      return preState.filter(item => item.id !== data);
    default:
      return preState;
  }
}